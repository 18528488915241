import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import Logo from '../images/manuela-weisz-logo.svg'

const NotFoundPage = () => (
  <Layout>
    <h1>
      <img
        src={Logo}
        alt="Manuel Weisz - Personal- und Organisationsentwicklung"
      />
    </h1>

    <h1>Seite nicht gefunden.</h1>
    <Link to="/">zur Startseite</Link>
  </Layout>
)

export default NotFoundPage
